import React from 'react'
import { SEO } from '../components/UI'
import {
  InternalHeader,
  InternalContent,
  InternalWorkshopNoTab,
  InternalInstructor,
  InternalTestimonial
} from '../components/Internal'
import { WHATSAPPLINK } from '../constants/routes'
import IconFlower from '../images/common/iconFlower.svg'
import styles from './leitura-do-campo-energetico-emocional.module.css'
export default function Page() {
  return (
    <>
      <SEO title='Introdutório de SoulCollage ®' />
      <InternalHeader withType>
        <small>Online</small>
        <h1>
          Introdutório de
          <br />
          <strong>
            Soulcollage<i className='copyRight'>®</i>
          </strong>
        </h1>
      </InternalHeader>
      <section className={styles.container}>
        <div className={styles.content}>
          <InternalContent>
            <h2>
              Sobre o <strong>Curso</strong>
            </h2>
            <p>
              Criado por Seena Frost, SoulCollage<i className='copyRight'>®</i> é um processo criativo, que utiliza
              recorte e colagem para acessar a intuição e criar um incrível baralho de cartas com um profundo
              significado pessoal que o ajudará com as perguntas e transições da vida.
            </p>
            <p>
              Essa técnica pode ser desenvolvida por terapeutas, arteterapeutas, psicólogos, profissionais da área da
              saúde, curadores, enfim, pessoas que trabalham com desenvolvimento de pessoas em grupos ou atendimentos
              individuais. Podem ser abordados diferentes temas, como por exemplo: gerenciamento da raiva, terapia de
              casal, grupo de mulheres, dependentes químicos e outros.
            </p>
            <p>
              SoulCollage<i className='copyRight'>®</i> é muito mais do que um processo de autodescoberta e
              empoderamento pessoal, provou ser uma ferramenta terapêutica valiosa, onde os participantes criam cartões
              com imagens significativas para si, através das imagens dão voz a seu cartão, este por sua vez, dará voz a
              sua Alma. Assim, vamos criando cartões que formarão nosso “Oráculo da Alma”. Através dos cartões,
              aprenderemos a reconhecer nossa historia pessoal e nossa história coletiva.
            </p>
            <h2>Objetivo</h2>
            <p>
              É um processo intuitivo e experiencial que tem como objetivo acessar nosso inconsciente, nossa sabedoria
              interior. Soul – Alma, Collage – colagem “Colagem da alma”, é a produção de imagens criativas que dão
              forma às várias faces da alma.
            </p>
            <h2>
              O que você <strong>vai aprender</strong>
            </h2>
            <dl className='learnItems'>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />O que é SoulCollage
                <i className='copyRight'>®</i>
              </dt>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                História da SoulCollage<i className='copyRight'>®</i>
              </dt>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Estrutura do processo da SoulCollage<i className='copyRight'>®</i>
              </dt>
              <dd>aprendendo sobre o Um e os Múltiplos</dd>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />O processo da SoulCollage
                <i className='copyRight'>®</i>
              </dt>
              <dd>como fazer os cartões, entendendo o Néter</dd>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />O processo de dar Voz ao cartão e o Diária da
                Alma
              </dt>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Cartões Transpessoais
              </dt>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Os 4 naipes e a História Local e História Pessoal
              </dt>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Naipe e seus significados
              </dt>
              <dd>Comitê Pessoal, Comunidade Afetiva, do Conselho Arquetípico e os Companheiros Energéticos</dd>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Como fazer a Leitura de cartões de SoulCollage<i className='copyRight'>®</i>
              </dt>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Oráculo da Alma
              </dt>
              <dd>usando os cartões para as perguntas e transições da vida</dd>
            </dl>
          </InternalContent>
          <div className='aside'>
            <InternalWorkshopNoTab>
              <h3>
                <small>Curso à Distância</small>
                Introdutório de SoulCollage<i className='copyRight'>®</i>
              </h3>
              <ul>
                <li>Aulas online agendadas ou gravadas</li>
                <li>Material complementar incluso</li>
                <li>Certificado de conclusão</li>
                <li>Sem pré-requisitos</li>
                <li>Curso destinado a todos os públicos </li>
                <li>Acesso por 12 meses a plataforma de ensino</li>
              </ul>
              <a
                href={WHATSAPPLINK}
                target='_blank'
                rel='noopener noreferrer'
                className={`button button--purpleReverse button internalWorkshop-button`}
              >
                Inscrição Curso Online <small></small>
              </a>
            </InternalWorkshopNoTab>
            <InternalInstructor />
            <InternalTestimonial />
          </div>
        </div>
      </section>
    </>
  )
}
